<template>
	<div class="container">
		<div class="d-flex justify-content-between align-items-center mb-4">
			<div>
				<h3 class="h3 mb-3">訪談報告</h3>
				<nav style="--bs-breadcrumb-divider: '>'">
					<ol class="breadcrumb mb-0">
						<li class="breadcrumb-item">
							<router-link :to="{ name: 'Client' }">客戶管理</router-link>
						</li>
						<li class="breadcrumb-item">
							<router-link :to="{ name: 'ClientDetail', params: { id: client.id } }">{{
								client.name
							}}</router-link>
						</li>
						<li class="breadcrumb-item">
							<router-link :to="{ name: 'ClientLesson', params: { id: client.id } }"
								>課程紀錄</router-link
							>
						</li>
						<li class="breadcrumb-item active">訪談報告</li>
					</ol>
				</nav>
			</div>
		</div>

		<div class="mb-4">
			<Vueform ref="searchForm$">
				<SelectElement
					name="lesson"
					placeholder="選擇課程"
					type="select"
					:native="false"
					:search="true"
					:items="lesson.list"
					:default="0"
					@select="
						(option) => {
							lesson.getData(option);
						}
					"
					columns="6"
					:can-clear="false"
					:can-deselect="false"
				/>
			</Vueform>
		</div>

		<div class="d-flex">
			<!-- 表單錨點區塊 -->
			<div v-if="!contrast.isShow" class="flex-shrink-0 me-4" style="width: 250px">
				<draggable
					:list="template.list"
					item-key="id"
					class="list-group list-group-flush mb-4"
					ghost-class="ghost"
					@start="dragging = true"
					@end="dragging = false"
					@change="template.saveOrder()"
					handle=".handle"
				>
					<template #item="{ element }">
						<a
							class="list-group-item d-flex align-items-center rounded border-0 mb-2 py-3"
							role="button"
							@click="jumpToAnchor(`#template-${element.id}`)"
						>
							<a class="handle me-3" role="button"><font-awesome-icon :icon="['fas', 'bars']" /></a>
							<span class="me-3">{{ element.title }}</span>
							<a class="close ms-auto" @click.stop="template.deleteData(element.id)" role="button">
								<font-awesome-icon :icon="['fas', 'trash-can']"
							/></a>
						</a>
					</template>
				</draggable>

				<div class="d-grid gap-3 px-5">
					<button class="btn btn-secondary" @click="addContrast.getList()">
						<font-awesome-icon :icon="['fas', 'clock-rotate-left']" class="me-2" />比較紀錄
					</button>

					<button class="btn btn-success" @click="addTemplate.getList()">
						<font-awesome-icon :icon="['fas', 'add']" class="me-2" />新增表單
					</button>
				</div>
			</div>

			<!-- 對比表單區塊 -->
			<div v-if="contrast.isShow" id="contrast" class="w-50 pe-3">
				<div class="card bg-light">
					<div class="card-header d-flex">
						{{ contrast.title }}
						<a class="text-primary ms-auto ps-2" role="button" @click="contrast.hideContrast"
							><font-awesome-icon :icon="['fas', 'xmark']" class="me-2" />取消對比</a
						>
					</div>
					<div class="contrast-wrapper card-body">
						<div v-if="contrast.list.length == 0" class="text-muted p-5 text-center bg-light rounded">
							該課程目前沒有任何訪問表單。
						</div>
						<div v-for="(item, index) in contrast.list" :key="item.id" :id="`template-${item.id}`">
							<div class="card mb-4">
								<div class="card-body">
									<FillBuilder
										:ref="
											(el) => {
												contrastFormRefs$[index] = el;
											}
										"
										:formDisabled="true"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 填寫表單區塊 -->
			<div class="bg-light p-3 rounded" :class="{ 'w-50': contrast.isShow }" style="width: calc(100% - 250px)">
				<div class="fill-list-wrapper">
					<div v-if="template.list.length == 0" class="text-muted p-5 text-center bg-light rounded">
						目前沒有任何訪問表單，請為本次課程選擇需要的表單。
					</div>
					<div v-for="(item, index) in template.list" :key="item.id" :id="`template-${item.id}`">
						<div class="card mb-4">
							<div class="card-body">
								<FillBuilder
									:ref="
										(el) => {
											fillFormRefs$[index] = el;
										}
									"
									@formOnChange="
										(data) => {
											template.formOnChange(item, data);
										}
									"
									:id="item.id"
									:clientID="client.id"
								/>
							</div>
						</div>
					</div>

					<div v-if="template.list.length > 0" class="text-center mt-5 mb-1">
						<button class="btn btn-primary" @click="template.saveAll">儲存</button>
					</div>
				</div>
			</div>
		</div>

		<!-- 新增表單 Modal -->
		<div ref="addTemplateModal$" class="modal fade" tabindex="-1">
			<div class="modal-dialog modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">選擇你要新增的表單</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal"></button>
					</div>
					<div class="modal-body">
						<div class="d-flex mb-2" style="width: 400px">
							<input
								type="text"
								class="form-control form-control-sm me-2"
								placeholder="標題"
								v-model="addTemplate.searchKeyword"
								@keyup.enter="addTemplate.getList()"
							/>
							<button class="flex-shrink-0 btn btn-sm btn-primary" @click="getList()">查詢</button>
						</div>
						<div class="mb-4">
							<a
								v-for="item in addTemplate.pageList"
								:key="item.id"
								class="d-block p-2"
								role="button"
								@click="addTemplate.addSubmit(item.id)"
								>{{ item.title }}</a
							>
						</div>

						<Pagination
							v-model="addTemplate.pagination.currentPage"
							:records="addTemplate.pagination.records"
							:per-page="addTemplate.pagination.perPage"
							:options="addTemplate.pagination.options"
							@paginate="addTemplate.getPageList"
						/>
					</div>
				</div>
			</div>
		</div>

		<!-- 選擇對比表單 Modal -->
		<div ref="contrastModal$" class="modal fade" tabindex="-1">
			<div class="modal-dialog modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">選擇對比表單</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal"></button>
					</div>
					<div class="modal-body">
						<div class="d-flex mb-2" style="width: 400px">
							<input
								type="text"
								class="form-control form-control-sm me-2"
								placeholder="標題"
								v-model="addContrast.searchKeyword"
								@keyup.enter="addContrast.getList()"
							/>
							<button class="flex-shrink-0 btn btn-sm btn-primary" @click="getList()">查詢</button>
						</div>
						<div class="mb-4">
							<table class="table table-hover mb-5">
								<thead class="bg-light">
									<tr>
										<th>上課日期</th>
										<th>課程名稱</th>
										<th class="text-center">引導員</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="item in addContrast.pageList"
										:key="item.id"
										@click="contrast.showContrast(item)"
									>
										<td>{{ formatDatetime(item.class_date) }}</td>
										<td>{{ item.lesson_name }}</td>
										<td class="text-center">
											<div v-for="doctor in item.doctors" :key="doctor.id">{{ doctor.name }}</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<Pagination
							v-model="addContrast.pagination.currentPage"
							:records="addContrast.pagination.records"
							:per-page="addContrast.pagination.perPage"
							:options="addContrast.pagination.options"
							@paginate="addContrast.getPageList"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import { apiTemplate, apiClassTemplate, apiClientLessonChangeOrder } from "@/assets/js/api.js";
import { formatDatetime, jumpToAnchor } from "@/assets/js/common.js";
import { checkForm1 } from "@/assets/js/checkCustomForm";
import { useIndexDB } from "@/assets/js/indexDB.js";
import { ref, reactive, onMounted, nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Modal } from "bootstrap";
import draggable from "vuedraggable";
import FillBuilder from "@/components/FillBuilder";

export default {
	name: "ClientReport",
	components: {
		draggable,
		FillBuilder,
	},
	setup() {
		const store = useStore();
		const route = useRoute();
		const router = useRouter();

		const dragging = ref(false);

		let clientIDB;
		let clientLessonIDB;

		const searchForm$ = ref(null);
		const addTemplateModal$ = ref(null);
		const contrastModal$ = ref(null);

		const fillFormRefs$ = ref([]);
		const contrastFormRefs$ = ref([]);

		// 客戶資料
		const client = reactive({
			id: -1,
			name: "",
		});

		// 課程紀錄
		const lesson = reactive({
			list: [], // 選單
			id: 0,
			data: {
				templates: [],
			},
			async getList() {
				lesson.list = (await clientLessonIDB.getAll()).map((item) => {
					return {
						value: item.id,
						label: `${formatDatetime(item.class_date)} ${item.lesson_name}`,
					};
				});
			},
			async getData(id) {
				lesson.id = id;
				lesson.data = await clientLessonIDB.get(id);

				template.getList();
				template.setDefault();
				contrast.hideContrast();
			},
		});

		// 課程表單
		const template = reactive({
			list: [],
			data: {
				templateID: 0,
			},
			async getList() {
				apiClassTemplate({
					method: "get",
					data: {
						class_id: lesson.id,
					},
				})
					.then((response) => {
						if (response.data.status == "0") {
							template.list = response.data.data;
							lesson.data.templates = response.data.data;

							let setForm = async (formRef$, item) => {
								let { template_id, title, content, template_content } = item;
								template_content = template_content.length == 0 ? {} : JSON.parse(template_content);
								content = content.length == 0 ? {} : JSON.parse(content);

								await formRef$.setFormData({
									templateID: template_id,
									title,
									questions: template_content,
								});
								await formRef$.setFillData(content);
							};

							nextTick(() => {
								let nullIndex = [];
								for (let i = 0; i < fillFormRefs$.value.length; i++) {
									if (fillFormRefs$.value[i] == null) {
										nullIndex.push(i);
										continue;
									} else {
										setForm(fillFormRefs$.value[i], template.list[i]);
									}
								}

								for (let i = 0; i < nullIndex.length; i++) {
									fillFormRefs$.value.splice(nullIndex[i], 1);
								}
							});
						} else {
							Swal.fire({
								icon: "warning",
								text: response.data.message,
							});
						}
					})
					.catch((err) => {
						console.error(err);
					});
			},
			deleteData(id) {
				Swal.fire({
					icon: "question",
					text: "確定要刪除嗎?",
					showCancelButton: true,
					confirmButtonText: "確定",
					cancelButtonText: "取消",
				}).then((result) => {
					if (result.isConfirmed) {
						setPageLoading(true);

						apiClassTemplate({
							method: "delete",
							id,
						}).then((response) => {
							setPageLoading(false);

							if (response.data.status == "0") {
								Swal.fire({
									icon: "success",
									text: response.data.message,
								});

								template.getList();
							} else {
								Swal.fire({
									icon: "warning",
									text: response.data.message,
								});
							}
						});
					}
				});
			},
			saveAll() {
				Promise.all(
					template.list.map((item, index) => {
						return new Promise((resolve, reject) => {
							fillFormRefs$.value[index]
								.getResult()
								.then((result) => {
									return apiClassTemplate({
										method: "put",
										id: item.id,
										data: {
											id: item.id,
											template_id: item.template_id,
											class_id: lesson.id,
											content: JSON.stringify(result),
										},
									});
								})
								.then((response) => {
									response.data.status == "0"
										? resolve(response)
										: reject({
												index,
												message: response.data.message,
										  });
								})
								.catch(() => {
									reject({
										index,
										message: "資料有誤",
									});
								});
						});
					})
				)
					.then((data) => {
						Swal.fire({
							icon: "success",
							text: data[0].data.message,
						});
					})
					.catch(({ index, message }) => {
						jumpToAnchor(`#template-${template.list[index].id}`);
						Swal.fire({
							icon: "warning",
							text: `${template.list[index].title} ${message}`,
						});
					});
			},
			formOnChange({ id, template_id }, result) {
				//檢查格式
				if (template_id == 1) {
					const isValid = checkForm1(result);

					if (!isValid) {
						Swal.fire({
							icon: "warning",
							text: "「自我五項評分表」資料格式錯誤，請重新建立表單",
						});
						return;
					}
				}

				apiClassTemplate({
					method: "put",
					id,
					data: {
						id,
						template_id,
						class_id: lesson.id,
						content: JSON.stringify(result),
					},
				}).then((response) => {
					if (response.data.status !== "0") {
						Swal.fire({
							icon: "warning",
							text: response.data.message,
						});
					}
				});
			},
			setDefault() {
				template.data.templateID = 0;
			},
			// 修改排序
			saveOrder() {
				const data = template.list.map((item, index) => {
					return {
						id: item.id,
						sort: index,
					};
				});

				apiClientLessonChangeOrder(data).then((response) => {
					if (response.data.status == "0") {
						return;
					} else {
						Swal.fire({
							icon: "warning",
							text: response.data.message,
						});
					}
				});
			},
		});

		// 新增表單 Modal
		const addTemplate = reactive({
			modal: {},
			dataList: [],
			pageList: [],
			searchKeyword: "",
			pagination: {
				currentPage: 1,
				records: 0,
				perPage: 20,
				options: {
					theme: "bootstrap4",
					hideCount: true,
				},
			},
			getPageList(currentPage) {
				let startIndex = (currentPage - 1) * addTemplate.pagination.perPage;
				let endIndex = startIndex + addTemplate.pagination.perPage;

				addTemplate.pageList = addTemplate.dataList.slice(startIndex, endIndex);
			},
			getList() {
				setPageLoading(true);

				apiTemplate({
					method: "get",
					data: {
						text: addTemplate.searchKeyword,
					},
				})
					.then((response) => {
						let list = response.data.data.filter((item) => {
							return item.status == 1;
						});
						addTemplate.dataList = list;

						addTemplate.pagination.currentPage = 1;
						addTemplate.pagination.records = list.length;
						addTemplate.getPageList(1);

						addTemplate.modal.show();
						setPageLoading(false);
					})
					.catch(() => {
						setPageLoading(false);
					});
			},
			addSubmit(id) {
				// 新增一筆表單至上課記錄
				setPageLoading(true);

				apiClassTemplate({
					method: "post",
					data: {
						class_id: lesson.id,
						template_id: id,
					},
				})
					.then((response) => {
						setPageLoading(false);
						if (response.data.status == "0") {
							lesson.getData(lesson.id);
							addTemplate.modal.hide();
						} else {
							Swal.fire({
								icon: "warning",
								text: response.data.message,
							});
						}
					})
					.catch(() => {
						Swal.fire({
							icon: "error",
							text: "ERROR",
						});
						setPageLoading(false);
					});
			},
		});

		// 對比表單
		const contrast = reactive({
			isShow: false,
			title: "",
			list: [],
			showContrast(item) {
				addContrast.modal.hide();
				contrast.isShow = true;

				apiClassTemplate({
					method: "get",
					data: {
						class_id: item.id,
					},
				}).then((response) => {
					if (response.data.status == "0") {
						contrast.title = item.lesson_name;
						contrast.list = response.data.data;

						let setForm = async (formRef$, item) => {
							let { title, content, template_id, template_content } = item;
							template_content = template_content.length == 0 ? {} : JSON.parse(template_content);
							content = content.length == 0 ? {} : JSON.parse(content);

							await formRef$.setFormData({
								templateID: template_id,
								title,
								questions: template_content,
							});
							await formRef$.setFillData(content);
						};

						nextTick(() => {
							let nullIndex = [];
							for (let i = 0; i < contrastFormRefs$.value.length; i++) {
								if (contrastFormRefs$.value[i] == null) {
									nullIndex.push(i);
									continue;
								} else {
									setForm(contrastFormRefs$.value[i], contrast.list[i]);
								}
							}

							for (let i = 0; i < nullIndex.length; i++) {
								contrastFormRefs$.value.splice(nullIndex[i], 1);
							}
						});
					} else {
						Swal.fire({
							icon: "warning",
							text: response.data.message,
						});
					}
				});
			},
			hideContrast() {
				contrast.isShow = false;
				contrast.title = "";
			},
		});

		// 選擇對比表單
		const addContrast = reactive({
			modal: {},
			dataList: [],
			pageList: [],
			searchKeyword: "",
			pagination: {
				currentPage: 1,
				records: 0,
				perPage: 20,
				options: {
					theme: "bootstrap4",
					hideCount: true,
				},
			},
			getPageList(currentPage) {
				let startIndex = (currentPage - 1) * addContrast.pagination.perPage;
				let endIndex = startIndex + addContrast.pagination.perPage;

				addContrast.pageList = addContrast.dataList.slice(startIndex, endIndex);
			},
			async getList() {
				setPageLoading(true);

				addContrast.dataList = (await clientLessonIDB.getAll()).filter((item) => item.id !== lesson.id);
				addContrast.pagination.records = addContrast.dataList.length;
				addContrast.pagination.currentPage = 1;

				addContrast.getPageList(1);

				addContrast.modal.show();
				setPageLoading(false);
			},
		});

		const setPageLoading = (isLoading) => store.dispatch("setPageLoading", isLoading);

		onMounted(async () => {
			clientIDB = await useIndexDB("clients", "client");
			clientLessonIDB = await useIndexDB("clientLessons", "clientLesson");

			//取得 客戶資訊
			const clientID = parseInt(route.params.clientID);
			const lessonID = parseInt(route.params.lessonID);

			client.id = clientID;
			client.name = (await clientIDB.get(clientID)).name;

			addTemplate.modal = new Modal(addTemplateModal$.value);
			addContrast.modal = new Modal(contrastModal$.value);

			lesson.getList(client.id);
			lesson.getData(lessonID);
			searchForm$.value.el$("lesson").update(lessonID);
		});

		return {
			dragging,
			addTemplateModal$,
			contrastModal$,
			searchForm$,
			fillFormRefs$,
			contrastFormRefs$,
			template,
			client,
			lesson,
			contrast,
			addTemplate,
			addContrast,
			jumpToAnchor,
			formatDatetime,
		};
	},
	beforeRouteEnter(to, from, next) {
		if (!to.params.clientID || !to.params.lessonID) {
			next({ name: "Client" });
		} else {
			next();
		}
	},
};
</script>

<style lang="scss" scoped>
:deep(.list-group-item) {
	&:not(.active) {
		background-color: $light;
	}

	&.active {
		a {
			color: #ffffff;
		}
	}
}

#contrast {
	.contrast-wrapper {
		height: 600px;
		overflow: auto;
	}
}

.fill-list-wrapper {
	height: 600px;
	overflow: auto;
}
</style>
